import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, ScrapyardVisitationForm } from "components";
import numeral from "numeral";

const CREATE_SCRAPYARD_VISITATION = gql`
  mutation CreateScrapyardVisitation(
    $description: String
    $meterRetirementSets: [ScrapyardVisitationMeterRetirementSetInput]!
    $date: Date!
  ) {
    createScrapyardVisitation(
      description: $description
      meterRetirementSets: $meterRetirementSets
      date: $date
    ) {
      _id
    }
  }
`;

export default function CreateScrapyardVisitationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createMeterScrapyardVisitation] = useMutation(
    CREATE_SCRAPYARD_VISITATION
  );
  // const searchParams = useSearch<LocationGenerics>();
  const form = useFormik({
    initialValues: {
      appointmentInfo: {
        description: "",
        date: "",
      },
      retirementsInfo: {
        retirements: [] as any[],
      },
    },
    onSubmit: async (values) => {
      await createMeterScrapyardVisitation({
        variables: {
          ...values.appointmentInfo,
          meterRetirementSets: values.retirementsInfo.retirements.map(
            (retirement) => ({
              meterRetirementSet: retirement?._id,
              meterRetirementSetCode: retirement?.code,
              totalMeters: numeral(retirement?.totalMeters || 0).value(),
            })
          ),
        },
      }).then(({ data }) => {
        if (data.createScrapyardVisitation._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Scrapyard Appointment Booked Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not book Scrapyard Appointment",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Book an Appointment"}
      description={"Provide the details to book an scrapyard appointment"}
      hideActions
      hidePadding
      size='5xl'
    >
      <ScrapyardVisitationForm form={form} />
    </Modal>
  );
}
