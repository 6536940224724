import AppointmentInfo from "./appointment-info-form";
import AppointmentInfoSummary from "./appointments-info-summary";
import RetirementsInfoForm from "./retirements-info-form";
import RetirementsInfoSummary from "./retirements-info-summary";

const formSteps = [
  {
    name: "Appointment Info",
    description: "Information about the appointment",
    accessor: "appointmentInfo",
    FormComponent: AppointmentInfo,
    SummaryComponent: AppointmentInfoSummary
  },
  {
    name: "Meter Retirement Sets Info",
    description: "Information about the meter retirement sets for this batch",
    accessor: "retirementsInfo",
    FormComponent: RetirementsInfoForm,
    SummaryComponent: RetirementsInfoSummary

  },
]

export default formSteps;
