import { FC } from "react";
import { wrapClick } from "utils";
import { useReactiveVar } from "@apollo/client";
import { IMeterRetirementSetFormSchema } from "./schema";
import { currentUserVar } from "apollo/cache/auth";
import lodash from "lodash";

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  steps: any[];
  values: IMeterRetirementSetFormSchema;
}

const SummaryPage: FC<SummaryPageProps> = ({
  values,
  handlePrevious,
  handleStep,
  submitLoading,
  steps,
  ...form
}) => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <form
      onSubmit={form.handleSubmit}
      className='flex-1 flex flex-col overflow-hidden'
    >
      <div className='space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1'>
        <div className=''>
          <div className='grid grid-cols-3 gap-6'>
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                District
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {lodash
                  .chain(currentUser?.contractor?.districts)
                  .keyBy("_id")
                  .get(values.info.district)
                  .get("name")
                  .value() || "N/A"}
              </div>
            </div>
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                Outcome
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {values.info?.outcome}
              </div>
            </div>
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                Meter Type
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {values.info?.meterType}
              </div>  
            </div>
            <div className='col-start-1 col-span-6'>
              <span className='block text-sm font-light text-gray-700'>
                Description
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {values.info?.description || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <div className='pt-6'>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-start-1 col-span-3'>
              <span className='block text-sm font-light text-gray-700'>
                Service Order Codes
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {values.info?.requestCodes?.join(", ") || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='submit'
          disabled={submitLoading}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SummaryPage;
