import { useQuery, useReactiveVar } from "@apollo/client";
import { currentDistrictVar } from "apollo/cache/auth";
import { OfficeHeader, Shimmers, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import { StatusBadge } from "components/core";
import config from "config";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTableData, useUrlState, wrapClick } from "utils";
import { GET_SERVICE_ORDERS } from "./queries";
import ViewReplacementServiceOrderContainer from "./view";
import RemoveMeterFromMeterRetirementSetContainer from "./remove";
import { GET_METER_RETIREMENT_SET } from "pages/retired-meter-sets/view";

const ReplacementServiceOrdersPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();

  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const currentDistrict = useReactiveVar(currentDistrictVar);

  const filter = useMemo(() => {
    return {
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "servicePointCode",
        "serviceRequestCode",
      ],
      ...(currentDistrict ? { district: currentDistrict } : {}),
      status: "Completed",
      meterRetirementSet: params.retirementSet,
    };
  }, [searchParams, currentDistrict, params.retirementSet]);

  const { data: retirementSetData, loading: retirementSetLoading } = useQuery(
    GET_METER_RETIREMENT_SET,
    {
      variables: {
        id: params.retirementSet,
      },
      notifyOnNetworkStatusChange: false,
    }
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_SERVICE_ORDERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        id: undefined,
      }),
    });
  }, [searchParams.orderStatus, navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeHeader />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"retired meters"}
            refetch={refetch}
            isRefetching={
              loading || (retirementSetLoading && networkStatus === 4)
            }
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Number
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Brand | Model
                </th>

                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Type
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Condition
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Removal Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Contractor | Agent
                </th>

                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>

                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>

                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.retiredMeter?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-700 dark:text-gray-500'>
                    {item?.retiredMeter?.modelMeta?.brandCode || "N/A"} -{" "}
                    {item?.retiredMeter?.modelMeta?.brandName || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.retiredMeter?.modelMeta?.modelCode || "N/A"} -{" "}
                    {item?.retiredMeter?.modelMeta?.modelName || "N/A"}
                  </div>
                </td>

                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.retiredMeter?.modelType || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    <StatusBadge
                      status={item?.resolution?.recoveryData?.meterCondition}
                      options={{}}
                    />
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {moment(
                      item?.resolution?.previousReading?.readingDate ??
                        item?.resolution?.finalReading?.readingDate ??
                        item?.resolvedAt ??
                        item?.completedAt ??
                        item?.reverseSyncedAt
                    ).format("DD/MM/YYYY HH:mm A") || "N/A"}{" "}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-700 dark:text-gray-500'>
                    {item?.farmingOutContractor?.name || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {[
                      (item?.farmingOutContractorUser?.lastName || "")?.trim(),
                      (item?.farmingOutContractorUser?.firstName || "")?.trim(),
                    ]
                      .join(" ")
                      .trim() || "N A"}{" "}
                    ({item?.farmingOutContractorUser?.code || "N A"})
                  </div>
                </td>

                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action='delete'
                      tooltip='Remove Meter'
                      disabled={
                        retirementSetData?.scrapyardVisitation?.status ===
                        "Completed"
                      }
                      onClick={dispatchAction(item?._id, "delete")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <>
              <ViewReplacementServiceOrderContainer
                open={modal === "view"}
                setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
              />
              <RemoveMeterFromMeterRetirementSetContainer
                open={modal === "delete"}
                setOpen={(val: boolean) => setModal(val ? "delete" : undefined)}
              />
            </>
          )}
        </>
      )}
    </main>
  );
};

export default ReplacementServiceOrdersPage;
