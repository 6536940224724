import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterRetirementSetMeterRemovalForm } from "components";
import { wrapClick } from "utils";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";

const REMOVE_METER_FROM_METER_RETIREMENT_SET = gql`
  mutation RemoveMeterFromMeterRetirementSet(
    $id: ID!
    $replacementServiceOrder: ID!
  ) {
    removeMeterFromMeterRetirementSet(
      replacementServiceOrder: $replacementServiceOrder
      id: $id
    ) {
      _id
    }
  }
`;

export default function RemoveMeterFromMeterRetirementSetContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  const [removeMeterFromMeterRetirementSet, { loading }] = useMutation(
    REMOVE_METER_FROM_METER_RETIREMENT_SET
  );

  const { data, loading: getLoading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const form = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await removeMeterFromMeterRetirementSet({
        variables: {
          replacementServiceOrder: searchParams.id,
          id: params.retirementSet,
        },
      }).then(({ data }) => {
        if (data.removeMeterFromMeterRetirementSet._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Replacement Service Order Removed Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not remove Replacement Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Remove Replacement Order from Meter Retirement Set"}
      description={
        "Confirm the removal of this replacement order from the retirement set"
      }
      loading={getLoading}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Removing..." : "Remove"}
          </button>
        </>
      )}
    >
      {data?.retiredMeter?._id && (
        <MeterRetirementSetMeterRemovalForm retiredMeter={data?.retiredMeter} />
      )}
    </Modal>
  );
}
