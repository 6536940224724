import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterRetirementSetMeterAdditionForm } from "components";
import { wrapClick } from "utils";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const ADD_METERS_TO_METER_RETIREMENT_SET = gql`
  mutation AddMetersToMeterRetirementSet(
    $id: ID!
    $replacementServiceOrderCodes: [String!]!
  ) {
    addMetersToMeterRetirementSet(
      replacementServiceOrderCodes: $replacementServiceOrderCodes
      id: $id
    ) {
      _id
    }
  }
`;

export default function AddMetersToMeterRetirementSetContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();

  const [addMetersToMeterRetirementSet, { loading }] = useMutation(
    ADD_METERS_TO_METER_RETIREMENT_SET
  );
  const form = useFormik({
    initialValues: {
      replacementServiceOrderCodes: [] as string[],
    },
    onSubmit: async (values) => {
      await addMetersToMeterRetirementSet({
        variables: {
          replacementServiceOrderCodes: values.replacementServiceOrderCodes,
          id: searchParams.id,
        },
      }).then(({ data }) => {
        if (data.addMetersToMeterRetirementSet._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Retirement Set Updated Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not update Meter Retirement Set",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Add Replacement Orders to Meter Retirement Set"}
      description={"Add replacement orders to the meter retirement set"}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        </>
      )}
    >
      <MeterRetirementSetMeterAdditionForm form={form} />
    </Modal>
  );
}
