import { gql, useQuery } from "@apollo/client";
import { MeterRetirementView, Modal } from "components";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_SERVICE_ORDER = gql`
  query GetRetiredReplacementServiceOrder($id: ID!) {
    retiredMeter: getReplacementServiceOrder(id: $id) {
      _id
      code
      farmingOutContractor {
        _id
        code
        name
      }
      farmingOutContractorUser {
        _id
        code
        username
        lastName
        firstName
      }
      meter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
          phase
        }
        modelType
      }
      retiredMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
          phase
        }
        modelType
      }
      resolution {
        previousReading {
          readingDate
          readingImageUrl
        }
        finalReading {
          readingDate
          readingImageUrl
        }
        property {
          propertyImageUrls
        }
        recoveredMaterials {
          material {
            _id
            code
            name
          }
          quantity
        }
        recoveryData {
          meterCondition
        }
      }
      resolvedAt
      completedAt
      reverseSyncedAt
      createdAt
      updatedAt
    }
  }
`;

export default function ViewReplacementServiceOrderContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='Retired Meter Information'
      size='4xl'
      description='Details of retired meter are shown below'
    >
      {data?.retiredMeter?._id && (
        <MeterRetirementView retiredMeter={data?.retiredMeter} />
      )}
    </Modal>
  );
}
