import * as Yup from "yup";

export interface IScrapyardVisitationFormSchema {
  appointmentInfo: {
    description: string;
    date: string;
  };
  retirementsInfo: {
    retirements: {
      _id: string;
      code: string;
      totalMeters: number;
      outcome: string;
      createdAt: string;
    }[];
  };
}

export const AppointmentInfoSchema = Yup.object().shape({
  description: Yup.string().required(),
  date: Yup.date().required(),
});

export const RetirementsInfoFormSchema = Yup.object().shape({
  retirements: Yup.array()
    .of(
      Yup.object()
        .shape({
          _id: Yup.string().required(),
          code: Yup.string().required(),
        })
        .required()
    )
    .min(1)
    .required(),
});
