import lodash from "lodash";
import { FC } from "react";
import { classNames } from "utils";

export interface MeterRetirementsItemsViewProps {
  retirements: {
    sn: string;
    status: string;
    statusReason: string;
    meterCode: string;
    meterSystemDetails: any;
  }[];
  status: "Failed" | "Success";
}

const MeterRetirementsItemsView: FC<MeterRetirementsItemsViewProps> = ({
  retirements,
  status,
}) => {
  const headers = lodash.toPairs({
    requestCode: "Order Number",
    meterCode: "Meter Number",
    servicePointCode: "Service Point",
    ...(status === "Failed" ? { statusReason: "Reason" } : {}),
  });

  if (retirements.length) {
    return (
      <table className="min-w-full  divide-y divide-gray-200 border-b border-b-gray-200 overflow-x-scroll">
        <thead className="bg-gray-50 sticky top-0 z-10">
          <tr>
            <th
              scope="col"
              className={classNames(
                "sticky left-0 bg-gray-50",
                "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
              )}
            >
              #
            </th>
            {headers?.map((column: any, idx: number) => (
              <th
                scope="col"
                key={idx}
                className={classNames(
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                {column?.[1]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {retirements?.map((retirement, key) => {
            const datum = {
              ...retirement,
              ...retirement?.meterSystemDetails,
            };
            return (
              <tr key={key}>
                <td
                  className={classNames(
                    "px-6 py-4  text-sm   bg-gray-50",
                    "text-gray-500"
                  )}
                >
                  {key + 1}
                </td>
                {headers?.map((column, idx) => {
                  return (
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4  text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {datum?.[column?.[0]] || "N/A"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <div className="flex-1 flex w-full p-6">
      <div className="relative flex flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-6 items-center justify-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
        {/* <UserPlusIcon
              className="mx-auto h-10 w-10 text-gray-400"
              stroke="currentColor"
              strokeWidth={1}
              aria-hidden="true"
            /> */}
        <h3 className="mt-2 text-md font-medium text-gray-900">
          No submission here
        </h3>
        <p className="mt-1 text-md text-gray-500">
          {status} submissions would appear here
        </p>
      </div>
    </div>
  );
};

export default MeterRetirementsItemsView;
