import * as Yup from "yup";

export interface IMeterRetirementSetFormSchema {
  info: {
    district: string;
    description: string;
    requestCodes: string[];
    outcome: string;
    meterType: string;
  };
}

export const InfoFormSchema = Yup.object().shape({
  district: Yup.string().notRequired(),
  description: Yup.string().required(),
  requestCodes: Yup.array()
    .of(Yup.string())
    .min(1, "At least one replacement service order code is required")
    .required("At least one replacement service order code is required"),
  outcome: Yup.string().required(),
  meterType: Yup.string().required(),
});
