import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterRetirementSetForm } from "components";
import { currentDistrictVar } from "apollo/cache/auth";

const CREATE_METER_RETIREMENT_SET = gql`
  mutation CreateRegularizationMeterRetirementSet(
    $district: ID!
    $description: String
    $requestCodes: [String!]!
    $outcome: MeterRetirementSetOutcome!

  ) {
    createRegularizationMeterRetirementSet(
      district: $district
      description: $description
      requestCodes: $requestCodes
      outcome: $outcome
    ) {
      _id
    }
  }
`;

export default function CreateRegularizationMeterRetirementSetContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createMeterRegularizationMeterRetirementSet] = useMutation(CREATE_METER_RETIREMENT_SET);
  // const searchParams = useSearch<LocationGenerics>();
  const currentDistrict = useReactiveVar(currentDistrictVar);
  const form = useFormik({
    initialValues: {
      info: {
        district: currentDistrict || "",
        description: "",
        requestCodes: [] as string[],
        outcome: "",
        meterType: "",
      },
    },
    onSubmit: async (values) => {
      await createMeterRegularizationMeterRetirementSet({
        variables: {
          ...values.info,
          district: currentDistrict,
        },
      }).then(({ data }) => {
        if (data.createRegularizationMeterRetirementSet._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Retired Set Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create RegularizationMeterRetirementSet",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Start Session"}
      description={
        "Provide the details to retire meters"
      }
      hideActions
      hidePadding
      size="5xl"
    >
      <MeterRetirementSetForm form={form} />
    </Modal>
  );
}
