import { classNames } from "utils";
import lodash from "lodash";
import moment from "moment";

const headers = lodash.toPairs({
  code: "Code",
  createdAt: "Created At",
  outcome: "Outcome",
  totalMeters: "Total Meters",
});

const RetirementsInfoSummary = ({ data }: any) => (
  <div className='border-2 border-dashed border-gray-300 divide-y-2 divide-gray-300 divide-dashed space-y-6 rounded-md p-3'>
    <table className='min-w-full flex-1 divide-y divide-gray-200 overflow-x-scroll'>
      <thead className='bg-gray-50 sticky top-0 z-10'>
        <tr>
          <th
            scope='col'
            className={classNames(
              "sticky left-0 bg-gray-50",
              "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
            )}
          >
            #
          </th>
          {headers?.map((column: any, idx: number) => (
            <th
              scope='col'
              key={idx}
              className={classNames(
                "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
              )}
            >
              {column?.[1]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='bg-white divide-y divide-gray-200'>
        {data?.retirements?.map((retirement: any, idx: number) => {
          console.log(retirement);
          return (
            <tr key={idx}>
              <td
                className={classNames(
                  "px-6 py-4 whitespace-nowrap text-sm sticky left-0 bg-gray-50",
                  "text-gray-500"
                )}
              >
                {idx + 1}
              </td>
              <td
                key={idx}
                className={classNames(
                  "px-6 py-4 whitespace-nowrap text-sm ",
                  "text-gray-500"
                )}
              >
                {retirement?.code || "N/A"}
              </td>
              <td
                key={idx}
                className={classNames(
                  "px-6 py-4 whitespace-nowrap text-sm ",
                  "text-gray-500"
                )}
              >
                {moment(retirement?.createdAt).format("DD/MM/YYYY") || "N/A"}
              </td>
              <td
                key={idx}
                className={classNames(
                  "px-6 py-4 whitespace-nowrap text-sm ",
                  "text-gray-500"
                )}
              >
                {lodash.startCase(retirement?.outcome) || "N/A"}
              </td>
              <td
                key={idx}
                className={classNames(
                  "px-6 py-4 whitespace-nowrap text-sm text-center",
                  "text-gray-500"
                )}
              >
                {retirement?.totalMeters || 0}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

export default RetirementsInfoSummary;
