import { TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface RescheduleScrapyardVisitationFormProps {
  form: FormikProps<{
    oldDate: string;
    newDate: string;
  }>;
}

const RescheduleScrapyardVisitationForm: FC<
  RescheduleScrapyardVisitationFormProps
> = ({ form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        {/* <span className="text-xs font-light">Staff Information</span> */}
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div className=''>
            <TextInput
              id='oldDate'
              label='Old Date'
              type='date'
              {...form}
              disabled
            />
          </div>
          <div className=''>
            <TextInput id='newDate' label='New Date' type='date' {...form} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescheduleScrapyardVisitationForm;
