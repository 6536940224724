import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import moment from "moment";
import { FC } from "react";

interface ScrapyardVisitationViewProps {
  scrapyardVisitation: {
    meta: any;
    _id: string;
    code: string;
    date: string;
    description: string;
    district: {
      _id: string;
      code: string;
      name: string;
    };
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    meterRetirementSets: {
      _id: string;
      meterRetirementSet: {
        createdAt: string;
        outcome: string;
      };
      meterRetirementSetCode: string;
      totalMeters: number;
    }[];
    createdAt: string;
    updatedAt: string;
  };
}

const ScrapyardVisitationView: FC<ScrapyardVisitationViewProps> = ({
  scrapyardVisitation,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className='p-6'>
        <div className=''>
          <span className='text-xs font-light'>Retirement Information</span>
          <div className='grid grid-cols-3 gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Code
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {scrapyardVisitation?.code || "N/A"}
              </div>
            </div>
            <div className='col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Description
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {scrapyardVisitation?.description || "N/A"}
              </div>
            </div>
         
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Created At
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {moment(scrapyardVisitation?.date).format(dateFormat)}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Created At
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {moment(scrapyardVisitation?.createdAt).format(
                  dateFormat + ", hh:mm A"
                )}
              </div>
            </div>
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                Created By
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {[
                  (scrapyardVisitation?.createdBy?.lastName || "")?.trim(),
                  (scrapyardVisitation?.createdBy?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrapyardVisitationView;
