import { FC, Fragment, useRef, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { classNames, wrapClick } from "utils";
import Loader from "./loader";

interface YesNoDialogProps {
  title: string;
  description?: string;
  type: "danger" | "warning" | "info";
  open: boolean;
  setOpen: (val: boolean) => void;
  renderActions?: () => JSX.Element;
  loading?: boolean;
}

const ConfirmationDialog: FC<PropsWithChildren<YesNoDialogProps>> = ({
  open,
  setOpen,
  children,
  renderActions,
  description,
  title,
  type,
  loading,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className={classNames("theme", "fixed z-10 inset-0 overflow-y-auto")}
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={classNames(

                "inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full max-w-2xl"
              )}
            >
              <div className='sm:flex sm:items-start sm:p-6 '>
                <div className='mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10'>
                  {
                    {
                      danger: (
                        <ExclamationTriangleIcon className='h-8 w-8 text-red-600' />
                      ),
                      warning: (
                        <ExclamationTriangleIcon className='h-8 w-8 text-yellow-600' />
                      ),
                      info: (
                        <ExclamationTriangleIcon className='h-8 w-8 text-blue-600' />
                      ),
                    }[type]
                  }
                </div>
                <div className='sm:flex sm:flex-col'>
                  <div className='mx-auto flex-shrink-0 flex flex-col items-start rounded sm:mx-0 sm:px-6  '>
                    <Dialog.Title
                      as='h3'
                      className='flex-1 text-lg leading-6 font-medium text-gray-900 dark:text-gray-50 text-center sm:text-left'
                    >
                      {title}
                    </Dialog.Title>
                    {description && (
                      <p className='w-full mt-2  text-base  text-gray-600 dark:text-gray-300 sm:w-auto sm:text-sm'>
                        {description}
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames(
                      "  overflow-y-auto",
                      loading ? "min-h-[50vh]" : "",
                    )}
                  >
                    {loading ? <Loader /> : children}
                  </div>
                </div>
              </div>
              {!loading && (
                <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
                  <>{renderActions?.()}</>
                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={wrapClick(() => setOpen(false))}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationDialog;
