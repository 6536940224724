import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import {
  ScrapyardVisitationView,
  Modal,
  ScrapyardVisitationRetirementsView,
} from "components";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useState } from "react";

export const GET_SCRAPYARD_VISITATION = gql`
  query GetScrapyardVisitation($id: ID!) {
    scrapyardVisitation: getScrapyardVisitation(id: $id) {
      _id
      code
      date
      district {
        _id
        code
        name
      }
      description
      status
      meterRetirementSets {
        meterRetirementSet {
          createdAt
          outcome
          _id
        }
        meterRetirementSetCode
        totalMeters
      }
      createdAt
      updatedAt
      createdBy {
        _id
        lastName
        firstName
        phoneNumber
        profileImageUrl
      }
      meta {
        totalMeterRetirements
        totalMeters
      }
    }
  }
`;

const orderTabs = [
  {
    name: "Appointment Details",
    href: "AppointmentDetails",
  },
  {
    name: "Meter Retirements",
    href: "MeterRetirements",
  },
];

export default function ViewScrapyardVisitationContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [orderTab, setOrderTab] = useState("AppointmentDetails");
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading, refetch } = useQuery(GET_SCRAPYARD_VISITATION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding
      size='5xl'
      title='Scrapyard Appointment  Details'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(() =>
              navigate({
                search: (old) => ({
                  ...old,
                  modal: "update",
                }),
              })
            )}
          >
            Add Meter Retirement Sets
          </button>
        </>
      )}
      description='View the details of the scrapyard  appointment'
    >
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {orderTabs.map((_orderTab) => (
              <button
                key={_orderTab.name}
                onClick={wrapClick(__setOrderTab(_orderTab.href))}
                className={classNames(
                  orderTab === _orderTab.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={orderTab === _orderTab.href ? "page" : undefined}
              >
                {_orderTab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-col w-full h-[55vh] overflow-y-auto'>
        {orderTab === "AppointmentDetails" && (
          <ScrapyardVisitationView
            scrapyardVisitation={data?.scrapyardVisitation}
          />
        )}
        {orderTab === "MeterRetirements" && (
          <ScrapyardVisitationRetirementsView
            scrapyardVisitation={data?.scrapyardVisitation}
            refetch={refetch}
          />
        )}
      </div>
    </Modal>
  );
}
