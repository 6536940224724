import { gql, useQuery } from "@apollo/client";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import { SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { classNames, wrapClick, wrapOnchange } from "utils";
import {
  RetirementsInfoFormSchema,
  IScrapyardVisitationFormSchema,
} from "./schema";
import moment from "moment";
import _ from "lodash";

interface ScrapyardVisitationInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: IScrapyardVisitationFormSchema["retirementsInfo"];
  values: IScrapyardVisitationFormSchema;
  handleCancel: () => void;
}

const GET_METER_RETIREMENT_SETS = gql`
  query GetMeterRetirementSets(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $outcome: MeterRetirementSetOutcome
    $searchFields: [String!]
    $contractor: ID
  ) {
    meterRetirementSets: getMeterRetirementSets(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      contractor: $contractor
      outcome: $outcome
    ) {
      code
      _id
      outcome
      scrapyardVisitation {
        _id
      }
      meta {
        totalSuccessRetirements
      }
      createdAt
    }
  }
`;

function RetirementSetDisplay({
  retirement,
  onClick,
  isActive,
}: {
  retirement: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      onClick={onClick}
      type='button'
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        " relative rounded-lg  px-3 py-3  shadow-sm flex items-center space-x-3 border-2 "
      )}
    >
      <div className='flex-1 min-w-0 text-left'>
        <div className='flex items-center justify-between'>
          <p className='text-sm font-medium text-gray-900 dark:text-white truncate'>
            {retirement?.code || "N/A"}
          </p>
          <span
            className={classNames(
              retirement?.outcome === "ToBeScrapped"
                ? "bg-amber-100 text-amber-800"
                : "",
              retirement?.outcome === "ToBeRefurbished"
                ? "bg-green-100 text-green-800"
                : "",
              "inline-flex rounded-full  px-1.5 py-1 text-[11px] items-center space-x-1"
            )}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 10 10'
              fill='currentColor'
              className='w-1.5 h-1.5'
            >
              <circle
                fillRule='evenodd'
                cx='5'
                cy='5'
                r='5'
                clipRule='evenodd'
              />
            </svg>
            <span>{_.startCase(retirement?.outcome) || "Unknown"}</span>
          </span>
        </div>
        <div className='flex items-center justify-between mt-1'>
          <p className='text-sm text-gray-500 dark:text-gray-400 truncate'>
            {moment(retirement?.createdAt).format("DD/MM/YYYY") || "N/A"}
          </p>
          <span className='text-gray-600 text-sm '>
            Meters : {retirement?.meta?.totalSuccessRetirements || 0}
          </span>
        </div>
      </div>
    </button>
  );
}

const RetirementsInfoForm: FC<ScrapyardVisitationInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
  values: parentValues,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema: RetirementsInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const [query, setQuery] = useState("");
  const [outcome, setOutcome] = useState();

  const filter = useMemo(
    () => ({
      search: query || undefined,
      outcome: outcome || undefined,
      searchFields: ["code"],
    }),
    [query, outcome]
  );
  const { loading, data } = useQuery(GET_METER_RETIREMENT_SETS, {
    variables: filter,
  });

  const retirementsSearchForm = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: (values) => {
      setQuery(values.code);
    },
  });

  const addItem = (item: any) => {
    form.setFieldValue("retirements", [...form.values?.retirements, item]);
  };
  const removeItem = (item: any) => {
    form.setFieldValue(
      "retirements",
      form.values?.retirements.filter(
        (retirement) => retirement._id !== item._id
      )
    );
  };

  useEffect(() => {
    console.log("form values", form.values);
    console.log("form errors", form.errors);
  }, [form]);
  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div>
          <span className='text-xs font-light'>
            Selected Meter Retirement Sets
          </span>
          {form?.values?.retirements?.length > 0 ? (
            <div className='border mt-2 border-gray-300 border-dashed p-2.5 divide-y divide-dashed divide-gray-300 rounded-md'>
              {form.values.retirements.map((retirement, key) => (
                <span
                  key={key}
                  className='inline-flex m-1 h-[28px] rounded-md items-center py-0.5 pl-2.5 pr-1 sm:text-sm bg-primary-100 text-gray-700'
                >
                  {retirement?.code}
                  <button
                    onClick={wrapClick(() => removeItem(retirement))}
                    type='button'
                    className='flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white'
                  >
                    <span className='sr-only'>Remove {retirement?.code}</span>
                    <svg
                      className='h-2 w-2'
                      stroke='currentColor'
                      fill='none'
                      viewBox='0 0 8 8'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-width='1.5'
                        d='M1 1l6 6m0-6L1 7'
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          ) : (
            <div className='flex  mt-2 w-full items-center justify-center'>
              <div className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                <FolderPlusIcon
                  className='mx-auto h-10 w-10 text-gray-400'
                  stroke='currentColor'
                  strokeWidth={1}
                  aria-hidden='true'
                />
                <h3 className='mt-2 text-sm font-medium text-gray-900'>
                  No retirement set selected
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  Get started by selecting a retirement set
                </p>
              </div>
            </div>
          )}
        </div>
        <div className='pt-6'>
          <span className='text-xs font-light'>
            Select Meter Retirement Set
          </span>
          <form
            onSubmit={retirementsSearchForm.handleSubmit}
            className='grid grid-cols-6 gap-6 mt-2'
          >
            {/* <div className='col-span-3 sm:col-span-2'>
              <SelectInput
                id='userIdentifier'
                label='Find Agent By'
                options={[
                  { label: "Email Address", value: "emailAddress" },
                  { label: "Full Name", value: "fullName" },
                  { label: "Ghana Card Number", value: "ghanaCardNumber" },
                  { label: "Phone Number", value: "phoneNumber" },
                  { label: "Staff Code", value: "code" },
                ]}
                placeholder='e.g. Mensah'
                {...contractorUserSearchForm}
              />
            </div> */}

            <div className='col-span-6 sm:col-span-2'>
              <TextInput
                id='code'
                label='Code'
                type='text'
                placeholder='e.g. MR12345678'
                {...retirementsSearchForm}
              />
            </div>
            <div className='col-span-6 sm:col-span-1 items-end justify-end flex'>
              <button
                type='submit'
                className='w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                {loading ? "Searching..." : "Search"}
              </button>
            </div>
            <div className='col-span-2'>
              <SelectInput
                id='outcome'
                label='Filter Outcome'
                options={[
                  { label: "--- Select Outcome ---", value: "" },
                  { label: "To Be Scrapped", value: "ToBeScrapped" },
                  { label: "To Be Refurbished", value: "ToBeRefurbished" },
                ]}
                placeholder='--- Select Outcome ---'
                {...form}
                values={{ outcome }}
                handleChange={wrapOnchange(setOutcome)}
              />
            </div>
          </form>
          <div className='grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200'>
            {data?.meterRetirementSets
              ?.filter((retirementSet: any) => {
                if (retirementSet?.meta?.totalSuccessRetirements > 0) {
                  return true;
                }
                return false;
              })
              ?.filter(
                (retirementSet: any) => !retirementSet?.scrapyardVisitation?._id
              )
              ?.map?.((meterRetirementSet: any) => {
                const isActive = form.values?.retirements?.some(
                  (retirement) => retirement._id === meterRetirementSet._id
                );
                console.log("isActive", meterRetirementSet.meta);
                return (
                  <RetirementSetDisplay
                    key={meterRetirementSet._id}
                    isActive={isActive}
                    onClick={wrapClick(() =>
                      isActive
                        ? removeItem(meterRetirementSet)
                        : addItem({
                            _id: meterRetirementSet._id,
                            code: meterRetirementSet.code,
                            totalMeters:
                              meterRetirementSet.meta.totalSuccessRetirements,
                            createdAt: meterRetirementSet.createdAt,
                            outcome: meterRetirementSet.outcome,
                          })
                    )}
                    retirement={meterRetirementSet}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RetirementsInfoForm;
