import moment from "moment";
import { FC, useState } from "react";
import { classNames } from "utils";
import lodash from "lodash";
import { ActionButton } from "components/buttons";
import RemoveMeterRetirementSetFromScrapyardVisitationContainer from "pages/scrapyard-visitations/remove-retirement";

interface ScrapyardVisitationRetirementsViewProps {
  refetch: () => void;
  scrapyardVisitation: {
    status: string;
    meterRetirementSets: {
      _id: string;
      meterRetirementSet: {
        createdAt: string;
        outcome: string;
        _id: string;
      };
      meterRetirementSetCode: string;
      totalMeters: number;
    }[];
  };
}



const ScrapyardVisitationRetirementsView: FC<
  ScrapyardVisitationRetirementsViewProps
> = ({ scrapyardVisitation, refetch }) => {
  const [current, setCurrent] = useState<string>();
  return (
    <>
      <div className='space-y-6 divide-y divide-gray-200'>
        <table className='min-w-full flex-1 divide-y divide-gray-200 overflow-x-scroll'>
          <thead className='bg-gray-50 sticky top-0 z-10'>
            <tr>
              <th
                scope='col'
                className={classNames(
                  "sticky left-0 bg-gray-50",
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                #
              </th>
              <th
                scope='col'
                className={classNames(
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                Code
              </th>
              <th
                scope='col'
                className={classNames(
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                Created At
              </th>
              <th
                scope='col'
                className={classNames(
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                Outcome
              </th>
              <th
                scope='col'
                className={classNames(
                  "px-6 py-3 mt-0 text-right text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                Total Meters
              </th>
              <th
                scope='col'
                className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
              ></th>{" "}
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {scrapyardVisitation?.meterRetirementSets?.map(
              (retirement, idx: number) => {
                console.log(retirement);
                return (
                  <tr key={idx}>
                    <td
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm sticky left-0 bg-gray-50",
                        "text-gray-500"
                      )}
                    >
                      {idx + 1}
                    </td>
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {retirement?.meterRetirementSetCode || "N/A"}
                    </td>
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {moment(retirement?.meterRetirementSet?.createdAt).format(
                        "DD/MM/YYYY"
                      ) || "N/A"}
                    </td>
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {lodash.startCase(
                        retirement?.meterRetirementSet?.outcome
                      ) || "N/A"}
                    </td>
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm text-right",
                        "text-gray-500"
                      )}
                    >
                      {retirement?.totalMeters || 0}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 w-[40px] text-sm text-gray-500 text-center'>
                      <div className='space-x-1 flex items-center justify-center'>
                        <ActionButton
                          action='delete'
                          tooltip='Remove Retirement Set '
                          disabled={
                            scrapyardVisitation?.meterRetirementSets?.length ===
                            1 || scrapyardVisitation?.status === "Completed"
                          }
                          onClick={() =>
                            setCurrent(retirement.meterRetirementSet?._id)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>

      <RemoveMeterRetirementSetFromScrapyardVisitationContainer
        open={!!current}
        setOpen={() => setCurrent(undefined)}
        values={{
          meterRetirementSet: current as string,
        }}
        refetch={refetch}
      />
    </>
  );
};

export default ScrapyardVisitationRetirementsView;
