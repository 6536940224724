import { Outlet, Route } from "react-location";
import {
  DashboardPage,
  ContractorsPage,
  ForgotPasswordPage,
  SigninPage,
  SettingsPage,
  MetersPage,
  ContractorUsersPage,
  InstallationServiceOrdersPage,
  MeterShipmentsPage,
  UsersPage,
  MeterModelsPage,
  ReplacementReasonsPage,
  ReplacementServiceOrdersPage,
  PrepayemtErrorsPage,
  BacklogInstallationServiceOrdersPage,
  RegularizationInstallationServiceOrdersPage,
  RetiredMeterSetsPage,
  RetiredMetersPage,
  RegularizedMeterSetsPage,
  RegularizedMetersPage,
  ExportsPage,
  ScrapyardVisitationsPage,
} from "pages";
import { UserRole } from "apollo/cache/auth";
import {
  ArrowDownTrayIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ClipboardDocumentIcon,
  ClockIcon,
  Cog8ToothIcon,
  CogIcon,
  CpuChipIcon,
  HomeIcon,
  SquaresPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export type RouteProps = Omit<Route, "children"> & {
  withRoles?: UserRole[];
  navigation?: boolean;
  navbar?: string;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
  },
  {
    path: "data-exports",
    element: <ExportsPage />,
    navbar: "Data Exports",
    sidebar: {
      label: "Data Exports",
      icon: ArrowDownTrayIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Data Exports",
      section: "General",
    },
  },
  {
    path: "installations",
    element: <InstallationServiceOrdersPage />,
    navbar: "Installation Orders",
    sidebar: {
      label: "Installation Orders",
      icon: SquaresPlusIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Installation Service Orders",
      section: "Orders",
    },
  },
  {
    path: "replacements",
    element: <ReplacementServiceOrdersPage />,
    sidebar: {
      label: "Replacement Orders",
      icon: ({ className }: any) => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke-width='1.5'
          stroke='currentColor'
          className={className}
        >
          <path
            stroke-linecap='round'
            stroke-linejoin='round'
            d='M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z'
          />
          <path
            d='M20 16H13.5L15.5 14'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M13.5 18H20L18 20'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ),
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Replacement Service Orders",
      section: "Orders",
    },
  },
  {
    path: "regularization-installations",
    element: <RegularizationInstallationServiceOrdersPage />,
    sidebar: {
      label: "Regularization Orders",
      icon: ({ className }: any) => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke-width='1.5'
          stroke='currentColor'
          className={className}
        >
          <path
            stroke-linecap='round'
            stroke-linejoin='round'
            d='M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z'
          />
          <path
            stroke-linecap='round'
            stroke-linejoin='round'
            d='M18 20l2-2m-2 2-2-2m-12 3 M15.5 14l2 2m-2-2-2 2'
          />
        </svg>
      ),
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularization Installation Service Orders",
      section: "Orders",
    },
  },
  {
    path: "backlog-installations",
    element: <BacklogInstallationServiceOrdersPage />,
    sidebar: {
      label: "Backlog Orders",
      icon: ClockIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Backlog Installation Service Orders",
      section: "Orders",
    },
  },
  {
    path: "meter-retirement-sets",
    element: <Outlet />,
    sidebar: {
      label: "Retired Meter Sets",
      icon: HomeIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Retired Meter Sets",
      section: "Removed Meters",
    },
    children: [
      {
        path: "/",
        element: <RetiredMeterSetsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Replaced Meters",
          section: "Removed Meters",
        },
      },
      {
        path: ":retirementSet/:code",
        element: <RetiredMetersPage />,
        meta: {
          layout: "App",
          breadcrumb: ({ code }: any) => `Replaced Meters - ${code}`,
          section: "Removed Meters",
        },
      },
    ],
  },
  {
    path: "regularized-meters",
    element: <Outlet />,
    sidebar: {
      label: "Regularized Meters",
      icon: HomeIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularized Meters",
      section: "Removed Meters",
    },
    children: [
      {
        path: "/",
        element: <RegularizedMeterSetsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Removed Meters",
          section: "Removed Meters",
        },
      },
      {
        path: ":retirementSet/:code",
        element: <RegularizedMetersPage />,
        meta: {
          layout: "App",
          breadcrumb: ({ code }: any) => `Regularized Meters - ${code}`,
          section: "Removed Meters",
        },
      },
    ],
  },
  {
    path: "scrapyard-visitations",
    element: <ScrapyardVisitationsPage />,
    sidebar: {
      label: "Scrapyard Appointments",
      icon: CalendarDaysIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Scrapyard Appointments",
      section: "Removed Meters",
    },
  },

  {
    path: "replacement-reasons",
    element: <ReplacementReasonsPage />,
    sidebar: {
      label: "Replacement Reasons",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Replacement Reasons",
      section: "Configurations",
    },
  },
  {
    path: "prepayment-errors",
    element: <PrepayemtErrorsPage />,
    sidebar: {
      label: "Prepayment Errors",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Prepayment Errors",
      section: "Configurations",
    },
  },
  {
    path: "meters",
    element: <MetersPage />,
    navbar: "Meters",
    sidebar: {
      label: "Meters",
      icon: CpuChipIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meters",
      section: "Meters",
    },
  },
  {
    path: "meters-models",
    element: <MeterModelsPage />,
    navbar: "Meter Models",
    sidebar: {
      label: "Meter Models",
      icon: CpuChipIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Models",
      section: "Meters",
    },
  },
  {
    path: "meter-shipments",
    element: <MeterShipmentsPage />,
    sidebar: {
      label: "Meter Shipments",
      icon: ClipboardDocumentIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Shipments",
      section: "Meters",
    },
  },
  {
    path: "users",
    element: <UsersPage />,
    navbar: "Users",
    sidebar: {
      label: "Users",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Users",
      section: "Configurations",
    },
    withRoles: ["Super"],
  },
  {
    path: "contractors",
    element: <Outlet />,
    sidebar: {
      label: "Farming Out Contractors",
      icon: BriefcaseIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Farming Out Contractors",
      section: "Configurations",
    },
    withRoles: ["Super", "Admin"],
    children: [
      {
        path: "/",
        element: <ContractorsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Contractors",
          section: "Configurations",
        },
        withRoles: ["Super", "Admin"],
      },
      {
        path: ":contractor",
        element: <ContractorUsersPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Farming Out Contractors Agents",
          section: "Configurations",
        },
        withRoles: ["Super", "Admin"],
      },
    ],
  },
  {
    path: "settings",
    element: <SettingsPage />,
    sidebar: {
      label: "Settings",
      icon: Cog8ToothIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "Configurations",
    },
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
];

export default routes;
