import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import ServiceInfoSummary from "components/cards/installation-service-order-resolution-view/service-info-summary";
import { FC } from "react";

interface MeterRetirementSetMeterRemovalFormProps {
  retiredMeter: any;
}

const MeterRetirementSetMeterRemovalForm: FC<
  MeterRetirementSetMeterRemovalFormProps
> = ({ retiredMeter }) => {
  return (
    <div className='space-y-6  divide-gray-200'>
      <div className='col-span-3 '>
        <div className='rounded-md bg-yellow-50 border border-yellow-400 px-2 flex items-center  py-3'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <ExclamationTriangleIcon
                className='h-5 w-5 text-yellow-400'
                aria-hidden='true'
              />
            </div>
            <div className='ml-3'>
              <h3 className='text-sm font-medium text-yellow-800'>
                This action is irreversible. Are you sure you want to remove
                this replacement order from the meter retirement set?
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-4 font-normal text-gray-900 dark:text-gray-100'>
              Meter Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about meter removed from premises
            </p>
          </div>
          <div />
        </div>
        <div className='mt-4'>
          <ServiceInfoSummary data={{ ...retiredMeter }} />
        </div>
      </div>
    </div>
  );
};

export default MeterRetirementSetMeterRemovalForm;
