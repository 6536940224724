import { SelectInput, TagArea, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick } from "utils";
import { InfoFormSchema, IMeterRetirementSetFormSchema } from "./schema";
import { DistrictPicker } from "containers";

interface InfoFormProps {
  handleNext: (values: IMeterRetirementSetFormSchema["info"]) => void;
  handlePrevious: () => void;
  initialValues: IMeterRetirementSetFormSchema["info"];
  values: IMeterRetirementSetFormSchema;
  handleCancel: () => void;
}

const InfoForm: FC<InfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
}) => {
  const form = useFormik<IMeterRetirementSetFormSchema["info"]>({
    initialValues,
    validationSchema: InfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div className='grid grid-cols-4 gap-4'>
          <div>
            <DistrictPicker
              id='district'
              disabled
              label='District'
              rawId
              {...form}
            />
          </div>
          <div className='col-start-1'>
            <SelectInput
              id='outcome'
              label='Outcome'
              options={[
                { label: "--- Select Outcome ---", value: "" },
                { label: "To Be Scrapped", value: "ToBeScrapped" },
                { label: "To Be Refurbished", value: "ToBeRefurbished" },
              ]}
              placeholder='--- Select Outcome ---'
              {...form}
            />
          </div>
          <div>
            <SelectInput
              id='meterType'
              label='Meter Type'
              options={[
                { label: "--- Select Meter Type ---", value: "" },
                { label: "Postpaid", value: "Postpaid" },
                { label: "Prepaid", value: "Prepaid" },
              ]}
              placeholder='--- Select Meter Type ---'
              {...form}
            />
          </div>
          <div className='col-span-2'>
            <TextInput
              id='description'
              placeholder='Description'
              label='Description'
              {...form}
            />
          </div>
        </div>
        <div className='pt-6'>
          <span className='text-xs font-light'>
             Service Orders Information
          </span>
          <div className='grid grid-cols-3 gap-6 mt-2'>
            <div className='col-start-1 col-span-3'>
              <TagArea
                id='requestCodes'
                label='Replacement Service Order Code(s)'
                placeholder='e.g. P13013093939'
                rows={20}
                height='min-h-[20rem]'
                {...form}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default InfoForm;
