import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  useTableData,
  withRoles,
  classNames,
} from "utils";
import config from "config";
import ViewContractorContainer from "./view";
import ActionButton, { Action } from "components/buttons/action-button";
import OfficeHeader from "components/layouts/office-header";
import { currentDistrictVar } from "apollo/cache/auth";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import CreateRetiredMeterTransferContainer from "./create";
import moment from "moment";
import numeral from "numeral";
import _ from "lodash";

const GET_METER_RETIREMENT_SETS = gql`
  query GetRegularizationMeterRetirementSets(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $fromDate: Date
    $toDate: Date
    $status: RegularizationMeterRetirementSetStatus
    $district: ID
  ) {
    rows: getRegularizationMeterRetirementSets(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      district: $district
    ) {
      _id
      code
      district {
        _id
        code
        name
      }
      description
      status
      outcome
      createdAt
      updatedAt
      createdBy {
        _id
        lastName
        firstName
        phoneNumber
        profileImageUrl
      }
      meta {
        totalRetirements
        totalSuccessRetirements
        totalFailedRetirements
      }
    }
    count: getRegularizationMeterRetirementSetsCount(
      search: $search
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      district: $district
      searchFields: $searchFields
    )
  }
`;

const RetiredMeterSetsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const navigateItem = (id: string, code: string) => () => {
    navigate({
      to: `./${id}/${code}`,
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const currentDistrict = useReactiveVar(currentDistrictVar);

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: searchParams.search?.toString() || undefined,
      searchFields: ["code", "description"],
      sort: searchParams.sort || "",
      ...(currentDistrict ? { district: currentDistrict } : {}),
    }),
    [searchParams, currentDistrict]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_METER_RETIREMENT_SETS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    }
  );
  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);
  const records = useTableData(data || {});
  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader
        renderActions={
          currentDistrict
            ? () => (
                <>
                  {withRoles(["Admin", "Super"])(
                    <button
                      type='button'
                      onClick={wrapClick(() => setModal("create"))}
                      className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                    >
                      <DocumentPlusIcon
                        className='-ml-0.5 mr-2 h-4 w-4'
                        aria-hidden='true'
                      />
                      Start New Session
                    </button>
                  )}
                </>
              )
            : undefined
        }
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"retired meter sets"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap w-full'
                >
                  Description
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Success Retirements
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Failed Retirements
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Total Retirements
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Outcome
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Status
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Created By
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.code}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {moment(item?.createdAt).format("DD/MM/YYYY HH:mm A") ||
                      "N/A"}{" "}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.description || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right border-b border-gray-200'>
                  {numeral(item?.meta?.totalSuccessRetirements).format("0,0") ??
                    "N/A"}
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right  border-b border-gray-200'>
                  {numeral(item?.meta?.totalFailedRetirements).format("0,0") ??
                    "N/A"}
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right  border-b border-gray-200'>
                  {numeral(item?.meta?.totalRetirements).format("0,0") ?? "N/A"}
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <span
                    className={classNames(
                      item?.outcome === "ToBeScrapped"
                        ? "bg-amber-100 text-amber-800"
                        : "",
                      item?.outcome === "Refurbish"
                        ? "bg-green-100 text-green-800"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{_.startCase(item?.outcome) || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <span
                    className={classNames(
                      item?.status === "Pending"
                        ? "bg-gray-100 text-gray-800"
                        : "",
                      item?.status === "InProgress"
                        ? "bg-amber-100 text-amber-800"
                        : "",
                      item?.status === "Completed"
                        ? "bg-green-100 text-green-800"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.status || "Unknown"}</span>
                  </span>
                </td>

                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {[
                      (item?.createdBy?.lastName || "")?.trim(),
                      (item?.createdBy?.firstName || "")?.trim(),
                    ]
                      .join(" ")
                      .trim() || "N A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action='expand'
                      onClick={navigateItem(item?._id, item?.code)}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {currentDistrict && (
        <CreateRetiredMeterTransferContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}

      {!!searchParams.id?.length && (
        <>
          <ViewContractorContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default RetiredMeterSetsPage;
