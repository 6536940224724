import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ConfirmationDialog } from "components";
import { wrapClick } from "utils";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";

const REMOVE_METER_RETIREMENT_SET_FROM_SCRAPYARD_VISITATION = gql`
  mutation RemoveMeterRetirementSetFromScrapyardVisitation(
    $id: ID!
    $meterRetirementSet: ID!
  ) {
    removeMeterRetirementSetFromScrapyardVisitation(
      id: $id
      meterRetirementSet: $meterRetirementSet
    ) {
      _id
    }
  }
`;

export default function RemoveMeterRetirementSetFromScrapyardVisitationContainer({
  open,
  setOpen,
  refetch,
  values,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  values: {
    meterRetirementSet?: string;
  };
}) {
  const [removeMeterRetirementSetFromScrapyardVisitation, { loading }] =
    useMutation(REMOVE_METER_RETIREMENT_SET_FROM_SCRAPYARD_VISITATION);
  const searchParams = useSearch<LocationGenerics>();
  const form = useFormik({
    initialValues: {
      ...values,
    },
    onSubmit: async (values) => {
        console.log(values);
        
      await removeMeterRetirementSetFromScrapyardVisitation({
        variables: {
          id: searchParams.id,
          meterRetirementSet: values.meterRetirementSet,
        },
      }).then(({ data }) => {
        if (data.removeMeterRetirementSetFromScrapyardVisitation._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Retirement Set Removed Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not remove Meter Retirement Set",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues(values);
  }, [values, form]);

  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      title={"Remove Meter Retirement Set"}
      description={
        "Are you sure you want to remove meter retirement set from this appointment?"
      }
      type='danger'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Removing..." : "Remove"}
          </button>
        </>
      )}
    ></ConfirmationDialog>
  );
}
