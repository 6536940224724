import { TagArea,  } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface MeterRetirementSetMeterAdditionFormProps {
  form: FormikProps<{
    replacementServiceOrderCodes: string[]
  }>;
}

const MeterRetirementSetMeterAdditionForm: FC<
  MeterRetirementSetMeterAdditionFormProps
> = ({ form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <span className='text-xs font-light'>Service Orders Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='col-start-1 col-span-3'>
            <TagArea
              id='replacementServiceOrderCodes'
              label='Replacement Service Order Code(s)'
              placeholder='e.g. P13013093939'
              rows={20}
              height='min-h-[20rem]'
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterRetirementSetMeterAdditionForm;
