import { Avatar } from "components/core";

const ContractorInfoSummary = ({ data }: any) => (
  <div className='border-2 border-dashed border-gray-300 divide-y-2 divide-gray-300 divide-dashed space-y-6 rounded-md p-3'>
    <div className='grid grid-cols-4 gap-4'>
      <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
        <Avatar alt={data?.contractor?.name || "N A"} size='xl' />
      </div>
      <div>
        <span className='block text-sm font-light text-gray-700'>
          Contractor Code
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {data?.contractor?.code || "N/A"}
        </div>
      </div>
      <div className='col-span-2'>
        <span className='block text-sm font-light text-gray-700'>
          Contractor Name
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {data?.contractor?.name || "N/A"}
        </div>
      </div>
      <div className=''>
        <span className='block text-sm font-light text-gray-700'>
          Contact Person
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {data?.contractor?.contactPerson?.fullName || "N/A"}
        </div>
      </div>
      <div>
        <span className='block text-sm font-light text-gray-700'>
          Phone Number
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {data?.contractor?.contactPerson?.phoneNumber || "N/A"}
        </div>
      </div>
      <div>
        <span className='block text-sm font-light text-gray-700'>
          Email Address
        </span>
        <div className='mt-1 block w-full sm:text-sm'>
          {data?.contractor?.contactPerson?.emailAddress || "N/A"}
        </div>
      </div>
    </div>
  </div>
);

export default ContractorInfoSummary;
