import { TextArea, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick } from "utils";
import {
  AppointmentInfoSchema,
  IScrapyardVisitationFormSchema,
} from "./schema";

interface AppointmentInfoProps {
  handleNext: (
    values: IScrapyardVisitationFormSchema["appointmentInfo"]
  ) => void;
  handlePrevious: () => void;
  initialValues: IScrapyardVisitationFormSchema["appointmentInfo"];
  values: IScrapyardVisitationFormSchema;
  handleCancel: () => void;
}

const AppointmentInfo: FC<AppointmentInfoProps> = ({
  initialValues,
  handleNext,
  handleCancel,
}) => {
  const form = useFormik<IScrapyardVisitationFormSchema["appointmentInfo"]>({
    initialValues,
    validationSchema: AppointmentInfoSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div className='grid grid-cols-2 gap-4'>
          <div className=''>
            <TextInput
              id='date'
              label='Date'
              type='date'
              placeholder='e.g. Mensah Enoch Nana Nyankah'
              {...form}
            />
          </div>

          <div className='col-span-2'>
            <TextArea
              id='description'
              placeholder='Description'
              label='Description'
              rows={6}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AppointmentInfo;
