import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  Modal,
  ScrapyardVisitationMeterRetirementSetAdditionForm,
} from "components";
import { wrapClick } from "utils";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

import * as Yup from "yup";
import numeral from "numeral";
const ADD_METER_RETIREMENT_SET_TO_SCRAPYARD_VISITATION = gql`
  mutation AddMeterRetirementSetToScrapyardVisitation(
    $id: ID!
    $meterRetirementSets: [ScrapyardVisitationMeterRetirementSetInput]!
  ) {
    addMeterRetirementSetToScrapyardVisitation(
      meterRetirementSets: $meterRetirementSets
      id: $id
    ) {
      _id
    }
  }
`;

export default function AddMeterRetirementSetsToScrapyardVisitationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();

  const [addMeterRetirementSetToScrapyardVisitation, { loading }] = useMutation(
    ADD_METER_RETIREMENT_SET_TO_SCRAPYARD_VISITATION
  );
  const form = useFormik({
    initialValues: {
      retirements: [] as any[],
    },
    validationSchema: Yup.object().shape({
      retirements: Yup.array().min(
        1,
        "At least one retirement set is required"
      ),
    }),
    onSubmit: async (values) => {
      await addMeterRetirementSetToScrapyardVisitation({
        variables: {
          id: searchParams.id,
          meterRetirementSets: values?.retirements.map((retirement) => ({
            meterRetirementSet: retirement?._id,
            meterRetirementSetCode: retirement?.code,
            totalMeters: numeral(retirement?.totalMeters || 0).value(),
          })),
        },
      }).then(({ data }) => {
        if (data.addMeterRetirementSetToScrapyardVisitation._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Retirement Sets Added Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not add Meter Retirement Sets",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Add Meters Retirement Sets "}
      size='4xl'
      description={"Add meter retirement sets to this scrapyard appointment"}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading
              ? "Adding Meter Retirement Sets..."
              : "Add Meter Retirement Sets"}
          </button>
        </>
      )}
    >
      <ScrapyardVisitationMeterRetirementSetAdditionForm form={form} />
    </Modal>
  );
}
