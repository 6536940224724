import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterRetirementSetForm } from "components";
import { currentDistrictVar } from "apollo/cache/auth";

const CREATE_METER_RETIREMENT_SET = gql`
  mutation CreateMeterRetirementSet(
    $district: ID!
    $description: String
    $requestCodes: [String!]!
    $outcome: MeterRetirementSetOutcome!
    $meterType: MeterRetirementMeterType!
  ) {
    createMeterRetirementSet(
      district: $district
      description: $description
      requestCodes: $requestCodes
      outcome: $outcome
      meterType: $meterType
    ) {
      _id
    }
  }
`;

export default function CreateMeterRetirementSetContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createMeterMeterRetirementSet] = useMutation(
    CREATE_METER_RETIREMENT_SET
  );
  // const searchParams = useSearch<LocationGenerics>();
  const currentDistrict = useReactiveVar(currentDistrictVar);
  const form = useFormik({
    initialValues: {
      info: {
        district: currentDistrict || "",
        description: "",
        requestCodes: [] as string[],
        outcome: "",
        meterType: "",
      },
    },
    onSubmit: async (values) => {
      await createMeterMeterRetirementSet({
        variables: {
          ...values.info,
          district: currentDistrict,
        },
      }).then(({ data }) => {
        if (data.createMeterRetirementSet._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Retirement Set Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create meter retirement set",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Create Meter Retirement Set"}
      description={"Provide the replacement service order and  codes necessary information to create a new meter retirement set"}
      hideActions
      hidePadding
      size='5xl'
    >
      <MeterRetirementSetForm form={form} />
    </Modal>
  );
}
