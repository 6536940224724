
import moment from "moment";

const AppointmentInfoSummary = ({ data }: any) => {

  return (
    <div className='border-2 border-dashed border-gray-300 divide-y-2 divide-gray-300 divide-dashed space-y-6 rounded-md p-3'>
      <div className=''>
        <div className='grid grid-cols-3 gap-6'>
     
          <div className='col-span-1'>
            <span className='block text-sm font-light text-gray-700'>
              Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {moment(data?.date).format("DD/MM/YYYY") || "N/A"}
            </div>
          </div>
          <div className='col-start-1 col-span-3'>
            <span className='block text-sm font-light text-gray-700'>
              Description
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.description || "N/A"}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default AppointmentInfoSummary;
